import React, { useState } from "react";
import { Image, Button } from "react-bootstrap";
import Avatar from "boring-avatars";

const UserAvatar = ({ user, size = 40, image }) => {
  const avatar_Name = user?.name
    ? user.name
      .split(" ")
      .slice(0, 2)
      .map((x) => x.charAt(0)
      .toUpperCase())
      .join("")
    : null;
  return (image && image !== "none") ||
    (user?.avatar && user?.avatar !== "none") ? (
    <Image
      width={size}
      height={size}
      src={image || user?.avatar}
      roundedCircle
    />
  ) : (
    <span className="in-name">
      <span> {avatar_Name}</span>
    </span>
  );
};

export default UserAvatar;