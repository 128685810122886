import React from 'react';
import OAuthLoginButton from './OAuthLoginButton';

const LoginButtons = ({ redirect, size }) => (
  <div className='btn-add-log'>
    <OAuthLoginButton size={10} provider="google" text="Login with Google" block redirect={redirect} outline />
    <OAuthLoginButton size={10} provider="linkedin" text="Login with LinkedIn" block redirect={redirect} outline />
  </div>
);

export default LoginButtons;
