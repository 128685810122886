import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { Container, Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Link from "next/link";
import AuthLinks from "./auth/AuthLinks";
import Logo from "./Logo";
import { NavArrowRight } from "iconoir-react";
import Gtag from "../lib/gtag";
import { default_offer_cover_image_url } from "../utils/constant";

const Header = ({
  meta = {
    description: "stride equity offer",
    name: "robots",
    content: "noindex, nofollow",
    image: default_offer_cover_image_url,
    slug: "offer?.slug",
    id: 0,
    PitchVideo: null,
  },
  title = "Stride Equity | Venture Capital investing for everyone",
  state = { user: {} },
  invertNav = false,
  style,
  noAuth,
  pitchVideo = null,
}) => {
  const router = useRouter();
  const { user } = state;
  if (state?.gtag) Gtag.init(state.gtag);

  useEffect(() => {
    setTimeout(() => Gtag.pageview(), 1000);
    setScrolled(document.documentElement.scrollTop > 10);
    window.addEventListener("scroll", (e) => {
      setScrolled(document.documentElement.scrollTop > 10);
    });
    setHomePage(router.pathname == "/");
    if (!navOpen) {
      document
        .getElementsByTagName("BODY")[0]
        .classList.remove("prevent-scroll");
    }
  }, []);

  const [scrolled, setScrolled] = useState(false);
  const [homePage, setHomePage] = useState(true);
  const [navOpen, setNavOpen] = useState(false);

  const scrolled_class = scrolled ? "scrolled" : "";
  const home_class = homePage ? "home" : "other";
  const nav_open_class = navOpen ? "mobile-nav-open" : "";

  const changeNavClass = () => {
    setNavOpen(!navOpen);
    if (!navOpen) {
      document.getElementsByTagName("BODY")[0].classList.add("prevent-scroll");
    } else {
      document
        .getElementsByTagName("BODY")[0]
        .classList.remove("prevent-scroll");
    }
  };

  const selectNavItem = () => {
    setNavOpen(false);
    document.getElementsByTagName("BODY")[0].classList.remove("prevent-scroll");
  };

  return (
    <div id="header" style={style}>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={process.env.HOSTING_URL + `offer/${meta?.slug}/${meta?.id}`}
        />

        <meta name="title" property="og:title" content={title} />
        <meta name="title"set content={title} />
        <meta property="og:description" content={meta.description ?? ""} />
        <meta property="og:image" content={meta?.image} />
        <meta property="og:image:secure_url" content={meta?.image} />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={process.env.HOSTING_URL + `offer/${meta?.slug}/${meta?.id}`}
        />
        <meta
          name="twitter:title"
          content={title}
        />
        <meta name="twitter:description" content={meta.description ?? ""}  />
        <meta name="twitter:image" content={meta?.image} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicon-16x16.png"
        />
        <link rel="manifest" href="/static/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href={process.env.GREENID_CSS}
        />

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,width=device-width"></meta>
        <meta
          name="google-site-verification"
          content="YRFOEMHZF_I4F7nCiTcSudHamZXhhYMPxp-892IUgZQ"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${state.gtag}`}
        />
        <script
          src={process.env.GREENID_SCRIPT1}
          type="text/javascript"
        ></script>
        <script
          src={process.env.GREENID_SCRIPT2}
          type="text/javascript"
        ></script>
      </Head>
      <header className={`${scrolled_class} ${home_class} ${nav_open_class}`}>
        <Navbar
          collapseOnSelect
          className={invertNav ? "inverted" : "regular"}
          expand="lg"
          fixed="top"
        >
          <Container>
            <Navbar.Brand href="/">
              <Logo
                className="logo"
                color={scrolled || !invertNav || navOpen ? "#181818" : "#fff"}
              />
            </Navbar.Brand>
            {!noAuth ? (
              <Nav
                id="authlinks"
                className="mobile-auth-links ml-auto d-inline-block d-lg-none"
              >
                <AuthLinks user={user} inverted={invertNav && !scrolled} />
              </Nav>
            ) : null}
            {/* <a
              className={!scrolled && homePage ? 'd-inline-block d-lg-none btn btn-primary order-lg-3 ml-auto ml-lg-0 header-button' : 'd-inline-block d-lg-none btn btn-primary order-lg-3 ml-auto ml-lg-0 scroll-header-button'}
              href="/invest"
            >
              Sign Up
            </a> */}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="ml-2"
              onClick={changeNavClass}
            >
              <svg
                id="nav-open"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 650 250"
                className={
                  !scrolled && invertNav
                    ? "toggle-icon-home"
                    : "toggle-icon-other"
                }
              >
                <defs></defs>
                <path
                  class="cls-1"
                  d="M75,275v50H725V275Zm0,200v50H725V475Z"
                  transform="translate(-75 -275)"
                />
              </svg>
              <svg
                id="nav-close"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="27.3345"
                  y1="9.19239"
                  x2="9.65684"
                  y2="26.8701"
                  stroke="#181818"
                />
                <line
                  x1="26.6274"
                  y1="26.8701"
                  x2="8.94973"
                  y2="9.19239"
                  stroke="#181818"
                />
              </svg>
            </Navbar.Toggle>
            <Navbar.Collapse id="primary-nav">
              <Nav
                activeKey={router.pathname}
                className="mx-auto"
                onClick={selectNavItem}
              >
                <Link href="/about">
                  <Nav.Link href="/about">
                    About
                    <NavArrowRight />
                  </Nav.Link>
                </Link>
                <Link href="/invest">
                  <Nav.Link href="/invest">
                    {" "}
                    Invest
                    <NavArrowRight />
                  </Nav.Link>
                </Link>
                <Link href="/raise">
                  <Nav.Link href="/raise">
                    Raise
                    <NavArrowRight />
                  </Nav.Link>
                </Link>
                <Link href="/news">
                  <Nav.Link href="/news">
                    News
                    <NavArrowRight />
                  </Nav.Link>
                </Link>
                <Link href="/contact">
                  <Nav.Link href="/contact">
                    Contact
                    <NavArrowRight />
                  </Nav.Link>
                </Link>
              </Nav>
              {/* <hr className='hr-color'/> */}
              {!noAuth ? (
                <Nav id="authlinks">
                  <AuthLinks user={user} inverted={invertNav && !scrolled} />
                </Nav>
              ) : null}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

export default Header;
