import React, { useState } from "react";
import Link from "next/link";
import { Badge, Table } from "react-bootstrap";
import UserAvatar from "./UserAvatar";
import IconButton from "../IconButton";
import Icons from "../icons";
import Status from "../Status";
import { NavArrowUp, NavArrowDown, ArrowSeparateVertical, Building } from "iconoir-react";

const User = ({ user, admin = false }) =>
  admin ? (
    <Link href={`/dashboard/users/edit/${user.id}`}>
      <a>
        {/* <p></p> */}
        <UserAvatar size={36} user={user} /> {user.name} <Role role={user.role} />
      </a>
    </Link>
  ) : (
    <span className="dashboard-user-detail">
      {/* <UserAvatar size={40} user={user} />  */}
      {user.name}
    </span>
  );

const variant = (role) =>
({
  member: "info",
  admin: "primary",
  superadmin: "success",
}[role || "member"]);

const Role = ({ role }) => <Badge variant={variant(role)}>{role}</Badge>;

const UserTable = ({ users, onTableClick, sortType }) => {
  const [sortState, setSortState] = useState(null);

  const handelClick = (event) => {
    onTableClick(event.currentTarget.textContent);

    setSortState(sortType);
  };
  return (
    <Table responsive className="card-table dashboard-table item-table">
      <thead>
        <tr>
          <th onClick={(event) => handelClick(event)}>
            <span>
              user
              {sortState === "DESC" ? (
                <NavArrowDown width={16} height={16} />
              ) : null}
              {sortState === "ASC" ? (
                <NavArrowUp width={16} height={16} />
              ) : null}
              {sortState === null ? (
                <span className="sort-indicator">
                  <ArrowSeparateVertical width={16} height={16} />
                </span>
              ) : null}
            </span>
          </th>
          <th onClick={(event) => handelClick(event)}>
            email
            <span>
              {sortState === "DESC" ? (
                <NavArrowDown width={16} height={16} />
              ) : null}
              {sortState === "ASC" ? (
                <NavArrowUp width={16} height={16} />
              ) : null}
              {sortState === null ? (
                <span className="sort-indicator">
                  <ArrowSeparateVertical width={16} height={16} />
                </span>
              ) : null}
            </span>
          </th>
          <th>role</th>
        </tr>
      </thead>
      <tbody>
        {users.length === 0 ? (
          <tr>
            <td colspan="3">
              <div className="table-placeholder">No Result Found </div>
            </td>
          </tr>
        ) : (
          users.map((user) => (
            <tr key={user.id}>
              <td>
                <User user={user} admin /> <Badges user={user} />
              </td>
              <td>{user.email} </td>
              <td>
                <Role role={user.role} />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

const Badges = ({ user }) => (
  <>
    {user.verifiedWholesale ? <Icons.Award size={16} /> : null}
    {user.verifiedId ? <Icons.Id size={16} /> : null}
    {user.companyVerified ? <Building height={18} width={18} /> : null}
    {user.googleId ? <Icons.Google size={16} /> : null}
    {user.linkedinId ? <Icons.Linkedin size={16} /> : null}
  </>
);

User.Table = UserTable;
User.Badges = Badges;
export default User;
