import React from 'react';

const Logo = ({ width, height, color }) => (
  <svg width="162" height="60" viewBox="0 0 162 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_317_3099)">
<path d="M131.295 0V2.76576C116.129 2.76576 103.789 14.9835 103.789 30C103.789 34.9671 105.138 39.6261 107.494 43.6383L105.069 45.0282C110.312 53.9793 120.093 60 131.297 60C148.031 60 161.596 46.5687 161.596 30C161.596 13.4313 148.026 0 131.295 0ZM104.264 30C104.264 15.2422 116.39 3.23612 131.295 3.23612V6.00188C144.681 6.00188 155.534 16.7474 155.534 30.0024C155.534 43.2573 144.681 54.0028 131.295 54.0028C122.333 54.0028 114.506 49.1863 110.314 42.0249L107.905 43.4055C105.592 39.4614 104.264 34.8824 104.264 30.0024V30Z" fill="#CC744B"/>
<path d="M7.52243 27.0696C7.46305 26.5216 7.21602 26.096 6.7766 25.7902C6.33955 25.4845 5.77187 25.3316 5.07592 25.3316C4.58662 25.3316 4.1662 25.4045 3.81466 25.5503C3.46312 25.6961 3.19472 25.8961 3.00945 26.1453C2.82418 26.397 2.72917 26.6839 2.72442 27.0061C2.72442 27.2742 2.78617 27.5071 2.91206 27.7023C3.03795 27.8975 3.20659 28.0644 3.42274 28.2009C3.63889 28.3373 3.87642 28.4501 4.13769 28.5419C4.39897 28.6336 4.66262 28.7112 4.92865 28.7747L6.14478 29.0757C6.63409 29.1886 7.10676 29.3415 7.56043 29.5343C8.01411 29.7272 8.42265 29.9694 8.78369 30.2611C9.14473 30.555 9.43213 30.9078 9.64353 31.3217C9.85493 31.7357 9.95944 32.2201 9.95944 32.7775C9.95944 33.5301 9.76467 34.1933 9.3775 34.7625C8.98796 35.334 8.4274 35.7785 7.69582 36.0983C6.96187 36.4182 6.0759 36.5781 5.03791 36.5781C3.99993 36.5781 3.15196 36.4229 2.41088 36.1148C1.6698 35.8043 1.09024 35.3528 0.674572 34.7601C0.258903 34.1675 0.0332536 33.4431 0 32.5894H2.3135C2.34675 33.0362 2.48689 33.4102 2.73154 33.7065C2.97619 34.0028 3.29685 34.2262 3.69115 34.3721C4.08544 34.5179 4.52961 34.5908 5.01891 34.5908C5.50821 34.5908 5.97852 34.5155 6.36568 34.3627C6.75285 34.2098 7.05451 33.9958 7.2754 33.723C7.49393 33.4501 7.60794 33.1279 7.61032 32.7587C7.60556 32.4247 7.5058 32.1472 7.31341 31.9285C7.12101 31.7098 6.84786 31.524 6.4987 31.3758C6.14953 31.2277 5.74336 31.0936 5.27781 30.9784L3.80041 30.6021C2.73154 30.3293 1.88833 29.9177 1.26839 29.3627C0.648444 28.8076 0.339661 28.0715 0.339661 27.1496C0.339661 26.3923 0.548684 25.7291 0.964353 25.1599C1.38002 24.5908 1.94771 24.1486 2.66504 23.8335C3.38236 23.5183 4.19707 23.3608 5.10442 23.3608C6.01177 23.3608 6.83361 23.5183 7.52956 23.8335C8.22551 24.1486 8.76944 24.5861 9.16848 25.1458C9.56515 25.7032 9.76942 26.3453 9.78367 27.0696H7.52006H7.52243Z" fill={color}/>
<path d="M10.8383 25.4986V23.5466H21.1944V25.4986H17.1826V36.3993H14.8501V25.4986H10.8383Z" fill={color}/>
<path d="M29.9543 36.3993H32.579L29.7714 31.31C29.8569 31.277 29.9401 31.2394 30.0208 31.2018C30.7049 30.8725 31.2251 30.4069 31.5766 29.8024C31.9282 29.198 32.1063 28.4831 32.1063 27.6599C32.1063 26.8368 31.9329 26.1218 31.5838 25.5056C31.2346 24.8895 30.7215 24.4073 30.0446 24.0663C29.3653 23.723 28.5292 23.5513 27.5316 23.5513H22.6647V36.4017H25.0162V31.6957H27.4081L29.9591 36.4017L29.9543 36.3993ZM25.0114 25.4939H27.1658C27.7525 25.4939 28.2323 25.5786 28.6052 25.7479C28.9757 25.9172 29.2536 26.1642 29.4365 26.4887C29.617 26.8133 29.7097 27.2037 29.7097 27.6599C29.7097 28.1162 29.6194 28.4995 29.4413 28.8123C29.2608 29.1251 28.9852 29.3603 28.6147 29.5202C28.2418 29.6825 27.7643 29.7625 27.1824 29.7625H25.0138V25.4962L25.0114 25.4939Z" fill={color}/>
<path d="M36.8212 23.5466V36.397H34.4697V23.5466H36.8212Z" fill={color}/>
<path d="M44.0253 36.3993H39.6263V23.5489H44.1132C45.4006 23.5489 46.5098 23.8053 47.4362 24.318C48.3625 24.8307 49.0775 25.5668 49.5763 26.524C50.0751 27.4812 50.3245 28.6289 50.3245 29.9624C50.3245 31.2959 50.0751 32.4506 49.5739 33.4125C49.0727 34.3744 48.353 35.1129 47.4124 35.6279C46.4718 36.143 45.3436 36.3993 44.0253 36.3993ZM41.9778 34.3862H43.9113C44.8163 34.3862 45.5692 34.2215 46.1749 33.8946C46.7782 33.5654 47.2343 33.0738 47.5383 32.42C47.8423 31.7662 47.9944 30.9454 47.9944 29.9624C47.9944 28.9793 47.8423 28.1632 47.5383 27.5118C47.2343 26.8603 46.7854 26.3735 46.1915 26.0489C45.5977 25.7244 44.8614 25.5621 43.9826 25.5621H41.9802V34.3838L41.9778 34.3862Z" fill={color}/>
<path d="M52.0941 36.3993V23.5489H60.5358V25.5009H54.4456V28.9887H60.0987V30.9407H54.4456V34.4473H60.5856V36.3993H52.0941Z" fill={color}/>
<path d="M66.8325 36.3993V23.5489H75.2742V25.5009H69.184V28.9887H74.8371V30.9407H69.184V34.4473H75.3241V36.3993H66.8325Z" fill={color}/>
<path d="M87.8226 33.5278C88.3452 32.5423 88.6065 31.357 88.6065 29.9718C88.6065 28.5865 88.3452 27.4012 87.8226 26.4182C87.3001 25.4327 86.5899 24.6778 85.6944 24.1557C84.7965 23.6336 83.7799 23.3702 82.6422 23.3702C81.5044 23.3702 80.4878 23.6312 79.59 24.1557C78.6921 24.6802 77.9819 25.4327 77.4618 26.4182C76.9392 27.4036 76.6803 28.5889 76.6803 29.9718C76.6803 31.3547 76.9416 32.5353 77.4618 33.5207C77.9843 34.5061 78.6945 35.2611 79.59 35.7855C80.4878 36.31 81.5044 36.5734 82.6422 36.5734C83.4712 36.5734 84.2384 36.4323 84.9391 36.1547L86.0127 37.5024H88.2929L86.4948 35.214C87.0222 34.7578 87.4663 34.198 87.8226 33.5278ZM80.7729 33.9675C80.2313 33.6218 79.8085 33.1138 79.5021 32.4436C79.1957 31.7709 79.0437 30.9501 79.0437 29.9741C79.0437 28.9981 79.1957 28.1773 79.5021 27.5047C79.8085 26.8321 80.2313 26.3241 80.7729 25.9807C81.3144 25.635 81.9367 25.4633 82.6422 25.4633C83.3476 25.4633 83.97 25.635 84.5115 25.9807C85.0531 26.3264 85.4759 26.8344 85.7823 27.5047C86.0887 28.175 86.2407 28.9981 86.2407 29.9741C86.2407 30.9501 86.0887 31.7709 85.7823 32.4436C85.5923 32.8598 85.3571 33.2103 85.0792 33.5019L83.9723 32.0814H81.8441L83.5994 34.365C83.3001 34.445 82.9819 34.485 82.6446 34.485C81.9391 34.485 81.3168 34.3133 80.7752 33.9675H80.7729Z" fill={color}/>
<path d="M98.6823 23.4501H101.034V31.8462C101.034 32.7658 100.815 33.5771 100.378 34.2756C99.9411 34.9741 99.326 35.5198 98.5374 35.9102C97.7464 36.3006 96.8248 36.4958 95.7678 36.4958C94.7108 36.4958 93.7821 36.3006 92.9912 35.9102C92.2002 35.5198 91.5874 34.9741 91.1527 34.2756C90.718 33.5771 90.4995 32.7681 90.4995 31.8462V23.4501H92.851V31.651C92.851 32.1872 92.9698 32.6623 93.2097 33.0809C93.4472 33.4995 93.7845 33.8264 94.2215 34.064C94.6562 34.3015 95.1716 34.4191 95.7678 34.4191C96.364 34.4191 96.8794 34.3015 97.3165 34.064C97.7535 33.8264 98.0908 33.4995 98.3283 33.0809C98.5659 32.6623 98.6823 32.1849 98.6823 31.651V23.4501Z" fill={color}/>
<path d="M105.713 23.4501V36.3006H103.362V23.4501H105.713Z" fill={color}/>
<path d="M107.609 25.4022V23.4501H117.965V25.4022H113.953V36.3029H111.62V25.4022H107.609Z" fill={color}/>
<path d="M119.425 23.4501H122.081L125.257 29.1345H125.383L128.558 23.4501H131.214L126.492 31.5075V36.3029H124.147V31.5075L119.425 23.4501Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_317_3099">
<rect width="161.591" height="60" fill="white"/>
</clipPath>
</defs>
</svg>
);

export default Logo;
