import React from 'react';
import { Dropdown, Badge } from 'react-bootstrap';

const variant = (status) =>
  ({
    draft: "base",
    submitted: "primary",
    pending: "warning",
    active: "success",
    confirmed: "success",
    published: "success",
    EOI: "info",
    finished: "warning",
    archived: "secondary",
    rejected: "secondary",
    canceled: "secondary",
    refunded: "success",
    successful: "success",
    batched: "warning",
    payment_pending: "warning",
    bank_processing: "warning",
    private: "secondary",
  }[status || "submitted"]);

const Status = ({ status }) => {
  if (status === "refund requested")
    return (
      <Badge className="status-badge" variant="primary">
        {status}
      </Badge>
    );
  else if (status === "refund rejected")
    return (
      <Badge className="status-badge" variant="secondary">
        {status}
      </Badge>
    );
  else if (status === "EOI ended")
    return (
      <Badge className="status-badge" variant="secondary">
        {status}
      </Badge>
    );
  else if (status === "offer ended")
    return (
      <Badge className="status-badge" variant="secondary">
        {status}
      </Badge>
    );
  else if (status === "payment_pending")
    return (
      <Badge className="status-badge" variant="warning">
        Payment Pending
      </Badge>
    );
  else if (status === "refund_pending")
    return (
      <Badge className="status-badge" variant="warning">
        Refund Pending
      </Badge>
    );
  else if (status === "payment_unsuccessful")
    return (
      <Badge className="status-badge" variant="secondary">
        Payment Unsuccessful
      </Badge>
    );
  else if (status === "private")
    return (
      <Badge className="status-badge" variant="secondary">
        Private
      </Badge>
    );
  else if (status === "private ended")
    return (
      <Badge className="status-badge" variant="primary">
        Private Ended
      </Badge>
    );
  else
    return (
      <Badge className="status-badge" variant={variant(status)}>
        {status || "submitted"}
      </Badge>
    );
};

const StatusSelect = ({
  statusList, selected, onChange, render, size, variant,
}) => (
  <Dropdown drop="up" style={{ display: 'inline' }}>
    <Dropdown.Toggle size={size} variant={variant || 'outline-info'}>
      <Status status={selected} />
    </Dropdown.Toggle>
    
    <Dropdown.Menu>
      {statusList.map((s) => (
        <Dropdown.Item 
          key={s}
          onClick={() => onChange(s)}
        >{s}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

Status.Select = StatusSelect;

export default Status;
