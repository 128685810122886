import React, { useState,useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Api from "../utils/apiCall";
import { Formik } from "formik";
import * as Yup from "yup";

const NewsLetterSignUp = () => {
    const onSubscribe = async (values, { resetForm }) => {
      if (values.email == "") setSuccess(false);
      let body = {
        email: values.email,
        subscribed: 1,
      };
      const response = await Api.post("user/subscriber", body);

      if (response.statusCode == 200) {
        setSuccess(true);
        setTimeout(function () {
          resetForm();
          setSuccess(false);
        }, 3500);
      }
    };

    const initialValues = {
        email: ""
    };

    const validation = Yup.object({
        email: Yup.string()
            .required("Please provide an email")
            .email("Invalid email format")
            .test("Unique Email", "This email has already been subscribed to the Newsletter", (email) =>
                Api.get(`user/subscriber`, { email })
            )
    });

    const [success, setSuccess] = useState(false);

    return (
        <div className="page-end-area">
            <Container size="xl" className="home-newsletter-content mt-4" >
                <Row>
                    <Col className="end-area-col">
                        <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubscribe}>
                            {({
                                errors, touched, values, handleChange, handleSubmit, isValid
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="newsletter-content-wrapper">
                                        <div className="newsletter-content">
                                            <div className="newsletter-headings-wrapper">
                                                <h2 className="newsletter-headings">Sign up for news & updates</h2>
                                            </div>
                                            <Form.Group controlId="email" className="newsletter-form">
                                                <Form.Control required value={values.email} type="email" placeholder="Your Email Address" className="email-input" onChange={handleChange} isValid={touched.email && !errors.email}
                                                    isInvalid={touched.email && errors.email} />
                                                <Form.Control.Feedback type="invalid" className="invalid-message">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                                {(success && isValid )? (<p class="text-success success-message">
                                                    You're now subscribed to our newsletter
                                                </p>) : null}
                                            </Form.Group>
                                        </div>
                                        <div className="newsletter-button-wrapper">
                                            <Button type="submit" className="newsletter-link-text"> Submit
                                                <svg width="21" height="12" className="mx-1" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill="black" />
                                                </svg>
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>


            </Container >
        </div>
 
    );
};

export default NewsLetterSignUp;