/* eslint-disable max-len */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Link from "next/link";

const Footer = ({ style }) => (
  <footer style={style}>
    <>
      <Container className="text-center text-md-left">
        <Row className="mb-5">
          <Col xs={6} md={4} lg={6}>
            <div className="mt-0 footer-logo">
              <img src="/static/Footer-logo.png" />
            </div>
          </Col>
          <Col xs={12} md={8} lg={6}>
            <div className="social-media-links">
              <a
                title="LinkedIn"
                target="_blank"
                className="mr-3 social-media-circle"
                href="https://www.linkedin.com/company/strideequity"
              >
                <div className="social-media-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="social-media-icon"
                  >
                    <path
                      d="M11.2 8.41861C11.7689 7.48837 12.7867 6.18837 15.0644 6.18837C17.8844 6.18837 20 8.11628 20 12.2628V20H15.7133V12.7907C15.7133 10.9767 15.0933 9.73721 13.5422 9.73721C12.3578 9.73721 11.6533 10.5721 11.3422 11.3791C11.232 11.7314 11.1838 12.1017 11.2 12.4721V20H6.91111C6.91111 20 6.96889 7.77209 6.91111 6.51163H11.2V8.41861ZM2.42667 0C0.96 0 0 1.00698 0 2.32558C0 3.64419 0.931111 4.65116 2.36889 4.65116H2.39778C3.89333 4.65116 4.82444 3.61395 4.82444 2.32558C4.82444 1.03721 3.89333 0 2.42667 0ZM0.255556 20H4.54222V6.51163H0.255556V20Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <a
                title="Facebook"
                target="_blank"
                className="mr-3 social-media-circle"
                href="https://www.facebook.com/StrideEquity"
              >
                <div className="social-media-icon">
                  <svg
                    width="12"
                    height="22"
                    viewBox="0 0 12 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="social-media-icon"
                  >
                    <path
                      d="M7.80308 5.55631V8.06675H11.869L11.3322 11.9779H7.80308V22.0001H3.55223V11.9632H0V8.06675H3.55223V5.17008C3.55223 1.82364 5.70462 8.45266e-05 8.83048 8.45266e-05C9.88911 -0.00237944 10.9471 0.0490273 12 0.154085V3.65208H9.83733C8.12671 3.65208 7.80308 4.42453 7.80308 5.55631Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <a
                title="Twitter"
                target="_blank"
                className="mr-3 social-media-circle"
                href="https://twitter.com/StrideEquity"
              >
                <div className="social-media-icon">
                  <svg
                    width="21"
                    height="17"
                    viewBox="0 0 21 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="social-media-icon"
                  >
                    <path
                      d="M20.928 2.00426C20.1553 2.35038 19.3262 2.57576 18.465 2.6804C19.3504 2.14915 20.0346 1.31203 20.3485 0.305871C19.5194 0.796875 18.6018 1.15104 17.6198 1.35227C16.839 0.515152 15.7202 0 14.4886 0C12.1141 0 10.1984 1.92377 10.1984 4.29025C10.1984 4.62831 10.2386 4.95833 10.3111 5.27225C6.73722 5.08712 3.57386 3.37263 1.45691 0.780777C1.08665 1.41667 0.877367 2.14915 0.877367 2.93797C0.877367 4.42708 1.634 5.73911 2.78504 6.51184C2.08475 6.48769 1.41667 6.29451 0.837121 5.97254V6.02888C0.837121 8.11364 2.31818 9.84422 4.2822 10.2386C3.91998 10.3352 3.54167 10.3916 3.14725 10.3916C2.87358 10.3916 2.59991 10.3674 2.34233 10.3191C2.88968 12.0256 4.47538 13.2652 6.35085 13.2973C4.88589 14.4484 3.03456 15.1326 1.02225 15.1326C0.676136 15.1326 0.330019 15.1165 0 15.0682C1.89962 16.2836 4.15341 17 6.58428 17C14.4806 17 18.803 10.456 18.803 4.78125C18.803 4.59612 18.803 4.41099 18.795 4.22585C19.6321 3.62216 20.3646 2.86553 20.9361 2.00426H20.928Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <a
                title="Youtube"
                target="_blank"
                className="mr-3 social-media-circle"
                href="https://www.youtube.com/channel/UCdf0R-usdi7h8-W4RJWUfrg"
              >
                <div className="social-media-icon">
                  <svg
                    width="21"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="social-media-icon"
                  >
                    <path
                      d="M20.5609 2.18581C20.3199 1.32548 19.6076 0.648064 18.7043 0.417742C17.0673 0 10.5 0 10.5 0C10.5 0 3.93273 0 2.29568 0.417742C1.39244 0.648064 0.680114 1.32548 0.439091 2.18581C0 3.74613 0 7 0 7C0 7 0 10.2539 0.439091 11.8142C0.680114 12.6745 1.39244 13.3519 2.29568 13.5823C3.93273 14 10.5 14 10.5 14C10.5 14 17.0673 14 18.7043 13.5823C19.6076 13.3519 20.3199 12.6745 20.5609 11.8142C21 10.2539 21 7 21 7C21 7 21 3.74613 20.5609 2.18581ZM8.35227 9.95468V4.04532L13.8409 7L8.35227 9.95468Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </Col>
        </Row>

        <Row md={6} className="footer-colums">
          <Col lg={3} md={6} xs={6}>
            <Nav className="flex-column footer-links">
              <h4>Explore</h4>
              <Link href="/invest">
                <Nav.Link href="/invest">Opportunities</Nav.Link>
              </Link>
              <Link href="/equity-crowdfunding">
                <Nav.Link href="/equity-crowdfunding">
                  Equity Crowdfunding
                </Nav.Link>
              </Link>
              <Link href="/about">
                <Nav.Link href="/about">About Us</Nav.Link>
              </Link>
              <Link href="/faq">
                <Nav.Link href="/faq">FAQ</Nav.Link>
              </Link>
            </Nav>
          </Col>
          <Col xs={{ span: 8, order: 2 }} md={{ span: 6, order: 1 }} lg={3}>
            <Nav className="flex-column footer-links">
              <h4>Legal</h4>
              <Link href="/guide">
                <Nav.Link href="/guide">Financial Services Guide</Nav.Link>
              </Link>
              <Link href="/general-csf-risk-warning">
                <Nav.Link href="/general-csf-risk-warning">
                  General CSF Risk Warning
                </Nav.Link>
              </Link>
              <Link href="/webterms">
                <Nav.Link href="/webterms">Website Terms of Use</Nav.Link>
              </Link>
              <Link href="/investor-terms-and-conditions">
                <Nav.Link href="/investor-terms-and-conditions">
                  Investor Terms &amp; Conditions
                </Nav.Link>
              </Link>
              <Link href="/privacy">
                <Nav.Link href="/privacy">Privacy</Nav.Link>
              </Link>
            </Nav>
          </Col>
          <Col xs={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} lg={3}>
            <Nav className="flex-column footer-links">
              <h4>Connect</h4>
              <Link href="/raise">
                <Nav.Link href="/raise">Raise Equity</Nav.Link>
              </Link>
              <Link href="/contact">
                <Nav.Link href="/contact">Contact Us</Nav.Link>
              </Link>
            </Nav>
          </Col>
        </Row>
      </Container>
      <div className="disclaimer">
        <Container>
          <Row className="text-left">
            <Col xs={12} className="small">
              <hr className="horizontal-line mb-2" />
              <p className="disclaimer-text">
                StrideCorp Equity Partners Pty Ltd (ABN 49 607 216 928) is
                authorised and licensed by the Australian Securities and
                Investment Commission (AFSL 482668).
                <br />
                CSF Offers and expressions of interest campaigns (EOIs) are
                facilitated by StrideCorp Equity Partners Pty Ltd and involve
                the provision of a crowdfunding service. The information on this
                website does not take into account any of your objectives and
                financial needs and you should seek your own independent
                financial advice. StrideCorp does not provide financial product
                advice. Please ensure you read the{" "}
                <Link
                  href="/general-csf-risk-warning"
                  className="disclaimer-link"
                >
                  General CSF Risk Warning
                </Link>{" "}
                and other important documents which can be found on this
                website.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  </footer>
);

export default Footer;
