export const StatusList = {
  Submitted: "submitted",
  Draft: "draft",
  EOI: "EOI",
  Active: "active",
  Finished: "finished",
  Rejected: "rejected",
  EOIEnded: "EOI ended",
  OfferEnded: "offer ended",
  private: "private",
  privateEnded: "private ended",
};
export const StatusAction = { Confirmed: "confirmed", Canceled: "canceled" };
export const SmallStatusAction = {
  Draft: "draft",
  Published: "published",
  Archived: "archived",
};
export const Roles = { Admin: "admin", Member: "member" };

export const TrusteeTypes = {
  IndividualTrustee: "Individual Trustee",
  CorporateTrustee: "Corporate Trustee",
};

export const privateRequestState = {
  applied: "Applied",
  accepted: "Accepted",
  rejected: "Rejected",
  applyNow: "ApplyNow",
  notFetched: "notFetched",
  privateEnded: "Private ended",
  privateOfferWithoutLogin: "privateOfferWithoutLogin",
};

export const default_reward_image_url =
  "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default_reward_image.jpg";

export const default_offer_cover_image_url =
  "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/stride-default-cover.png";

export const default_team_member_image_url =
  "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default_teammember.png";



export const socialMediaType = {
  instagram: {
    image: "",
    name: "instagram",
  },
  facebook: {
    image: "",
    name: "facebook",
  },
  linkedIn: {
    image: "",
    name: "linkedIn",
  },
  twitter: {
    image: "",
    name: "twitter",
  },
};
export const default_offer_cover_image_url_svg =
  "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg";

export const preMoneyValuationMaxValue = 1000000000000;
export const postMoneyValuationMaxValue = 1000000000000;
export const issuerFeesMaxValue = 1000000000000;
export const pitchVideoInstructionsTime = 8000
