import React from 'react';
import { Button, Spinner, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Api from "../../utils/apiCall";

const ResetPasswordForm = ({ complete, modal }) => {
  var dayjs = require("dayjs");
  const userValidationSchema = Yup.object({
    email: Yup.string()
      .required("Please provide an email")
      .email("Invalid email format"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values, { setFieldError }) => {
    values.expiryDate = dayjs();
    const result = await Api.post("user/resetpassword", values);
    if (result.error === "Incorrect username")
      setFieldError("email", "Invalid email");
    else {
      const slug = "resetpassword";
      window.open(
        process.env.HOSTING_URL +
          "/dashboard/users/" +
          result.user.id +
          "/" +
          slug,
        "_blank"
      );
      complete(result.user);
    }
  };

  return (
    <Formik
      validationSchema={userValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        values,
        handleChange,
        handleSubmit,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="text-left">
          <div className={modal ? "modal-body" : null}>
            <Form.Group controlId="email">
              <Form.Label>Your Email </Form.Label>
              <Form.Control
                required
                autoComplete="email"
                type="email"
                placeholder="john.doe@domain.com"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className={modal ? "modal-footer" : null}>
            <Button
              variant="primary"
              size="lg"
              block
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" role="status" />
              ) : null}
              &nbsp;Reset Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
